/** List of options expected by the UI and db */

export const factPersonsGroups = {
  fpSpokenViolences: {
    label: "La victime a subi une violence verbale",
    options: [
      {
        id: "fpSpokenViolences0",
        value: "Injure, provocation, outrage",
        info: "Paroles contre la personne ou sa profession, gestes obscènes ou de provocation pour mépriser, rabaisser, intimider ou chercher la bagarre, parler très près du visage, cracher par terre. Si crachat au visage ou sur la personne aller à l'item La victime a subi une violence physique et cocher violence volontaire sans arme. Si menace verbale ou par geste explicite de mort et/ou d’atteinte à l’intégrité physique : cocher menace de mort et d’atteinte à l’intégrité physique.",
        level: 1,
      },
      {
        id: "fpSpokenViolences1",
        value: "Propos discriminatoire",
        info: "Quand les injures et outrages se rapportent spécifiquement à : la race, l'ethnie, la nation, au pays, la religion, au sexe (propos sexistes, genre, orientation sexuelle), activités syndicales, opinions politiques.",
        level: 1,
      },
      {
        id: "fpSpokenViolences2",
        value:
          "Menace de mort et d’atteinte à l’intégrité physique ou d’atteinte aux biens",
        info: "À un personnel de santé, sa famille, autre personne. Il faut une formulation ou un geste explicite : « Je vais te tuer, t’égorger, te casser la gueule, etc.», montrer les poings, faire le geste du couteau qu’on passe sous sa gorge, « Je vais brûler ta maison », « Je vais faire sauter l'hôpital »,  etc.",
        level: 2,
      },
      {
        id: "fpSpokenViolences3",
        value: "Menace avec arme par nature ou par destination",
        info: [
          "Arme par nature : arme à feu ; arme blanche dont les objets contondants: poing américain, tonfa, nunchaku, etc. ; bombe lacrymogène...",
          "Arme par destination : objet qui va être utilisé comme arme soit par détournement de son usage naturel à des fins de violence (canne de marche, chaise, clé, couverts, déambulateur, etc.) soit parce que l'auteur a délibérément transformé l'objet dans le but d'en faire une arme (petite cuillère aiguisée, etc.).",
        ],
        level: 3,
      },
    ],
  },
  fpPhysicalViolences: {
    label: "La victime a subi une violence physique",
    options: [
      {
        id: "fpPhysicalViolences0",
        value: "Maltraitance volontaire ou par négligence",
        info: "Cet élément concerne uniquement la relation d'un personnel de santé envers un patient/résident (ex : négliger le patient/résident qui attend un soin de nursing, etc.)",
        level: 3,
      },
      {
        id: "fpPhysicalViolences1",
        value: "Violence volontaire sans arme",
        info: "Bousculade, coup, morsure, crachat au visage et sur la personne, saisir une personne à la gorge. Attention: une personne souffrant d’un Trouble Psychique ou Neuro-psychique (TPN), à savoir une abolition partielle ou totale de son discernement, est considérée comme commettant une violence volontaire (cocher également la case TPN). Une personne sous l’emprise manifeste d’alcool ou de stupéfiants commet une violence volontaire car c’est elle qui s’est mise dans cet état (ne pas cocher la case TPN).",
        level: 3,
      },
      {
        id: "fpPhysicalViolences2",
        value: "Violence volontaire avec arme par nature ou par destination",
        info: [
          "Arme par nature: arme à feu ; arme blanche dont les objets contondants : poing américain, tonfa, nunchaku, etc. ; bombe lacrymogène.",
          "Arme par destination: objet qui va être utilisé comme arme soit par détournement de son usage naturel à des fins de violence (canne de marche, chaise, clé, couverts, jouet, etc.), soit parce que l'auteur a délibérément transformé l'objet dans le but d'en faire une arme (petite cuillère aiguisée, etc.).",
        ],
        level: 4,
      },
      {
        id: "fpPhysicalViolences3",
        value: "Autre fait qualifié de crime",
        info: "Meurtre et tentative, violences volontaires entraînant mutilation ou infirmité permanente, enlèvement, séquestration",
        precision: "fpPhysicalViolencesPrecision",
        level: 4,
      },
    ],
  },
  fpSexualViolences: {
    label: "La victime a subi une violence sexuelle",
    options: [
      {
        id: "fpSexualViolences0",
        value: "Exhibition sexuelle",
        info: "Se montrer nu de façon intentionnelle à la vue du public (personnel de santé ou autres personnes).",
        level: 2,
      },
      {
        id: "fpSexualViolences1",
        value: "Harcèlement sexuel",
        info: [
          "2 cas : ",
          "- Agissements répétés de comportements, propos, usage réseaux sociaux, courriel, téléphone, textos, écrits.",
          "- Agissements non répétés en usant de toute forme depression grave dans le but réel ou apparent d'obtenir un acte de nature sexuelle.",
          "Si propos non répétés à caractère sexiste cocher la case propos discriminatoire dans la rubrique « La victime a subi une violence verbale ».",
        ],
        level: 2,
      },
      {
        id: "fpSexualViolences2",
        value: "Agression sexuelle",
        info: "L’agression sexuelle est une atteinte sexuelle sans pénétration commise avec contrainte, menace, surprise sans le consentement de la victime. Si pénétration, cocher viol.",
        level: 3,
      },
      {
        id: "fpSexualViolences3",
        value: "Viol",
        info: "Tout acte de pénétration sexuelle, de quelque nature qu'il soit, ou tout acte bucco-génital commis sur la personne d'autrui ou sur la personne de l'auteur par violence, contrainte, menace ou surprise est un viol.",
        level: 4,
      },
    ],
  },
  fpPsychologicalViolences: {
    label: "La victime a subi une violence psychologique",
    options: [
      {
        id: "fpPsychologicalViolences0",
        value: "Automutilation",
        info: "C’est une violence psychologique sur ceux qui constatent ce fait. S’il n’y a pas de motif particulier, cocher alors la case : « Pas de motif apparent ».",
        level: 2,
      },
      {
        id: "fpPsychologicalViolences1",
        value: "Constat d'un suicide ou d'une tentative",
        info: "C’est une violence psychologique sur ceux qui constatent ce fait. S’il n’y a pas de motif particulier, cocher alors la case : « Pas de motif apparent ».",
        level: 2,
      },
      {
        id: "fpPsychologicalViolences2",
        value: "Harcèlement moral",
        info: "Agissements répétés de comportements, propos, réseaux sociaux, courriel, téléphone, SMS, écrits qui troublent la tranquillité de la victime ou dégradent les conditions de travail. Ex: répétitions d’appels téléphoniques à la suite du refus d’un médecin de délivrer une ordonnance, d’une vengeance d’un soin considéré comme mal fait, etc.",
        level: 2,
      },
    ],
  },
  fpDiscriminations: {
    label: "La victime a été discriminée",
    options: [
      {
        id: "fpDiscriminations0",
        value:
          "Refus de délivrer un bien ou d'un service en raison de critères discriminatoires",
        info: "Le fait (ici uniquement pour un personnel de santé ou un prestataire) de refuser de délivrer un bien ou un service en raison d’une distinction opérée à propos de : l'origine, le sexe, la religion, l'opinion politique...",
        level: 2,
      },
      // Hack to make the field fpDiscriminations an array (like the other fields), not a boolean
      { value: "N/A", hidden: true },
    ],
  },
  fpNoRespects: {
    label:
      "Les auteurs n’ont pas respecté les règles du lieu / ont eu un comportement incivique",
    options: [
      {
        id: "fpNoRespects0",
        value: "Nuisance, chahut, fugue",
        info: "Non-respect des règles de l’établissement (horaires de visites, stationnement, niveau sonore d’un appareil, fumer dans un espace interdit...). Parler exagérément fort ou ameuter le public pour parvenir à ses fins, taper sur les meubles, faire le siège d’un bureau avec un personnel à l’intérieur pour obtenir une décision, ne pas respecter les règles de la laïcité, etc.",
        level: 1,
      },
      {
        id: "fpNoRespects1",
        value:
          "Consommation ou détention sur place d’alcool et/ou de produits stupéfiants pour son propre usage",
        level: 1,
      },
    ],
  },
  fpOthers: {
    label: "Autres faits",
    options: [
      {
        id: "fpOthers0",
        value: "Atteinte à la vie privée et/ou au droit à l’image",
        info: "Atteinte à la vie privée: fait de filmer, photographier et/ou enregistrer vos propos sans vous demander l’autorisation. Atteinte au droit à l’image: fait de diffuser ensuite film, photo/enregistrement sonore dans les médias (presse, audio, vidéo) sans votre autorisation. Attention: La chambre d’un établissement est un lieu privé, mais un établissement (public ou privé) et un cabinet, une officine ne sont pas un lieu privé. Donc il n’y pas d’atteinte à la vie privée si vous êtes filmé dans les couloirs ou encore une salle d’attente. En revanche la diffusion de votre image peut dans certaines circonstances être une violation du droit à l’image.",
        level: 2,
      },
      {
        id: "fpOthers1",
        value:
          "Injures et diffamations publiques (voie de presse - tout autre moyen de communication)",
        info: "Si l’injure et la diffamation ne sont pas rendues publiques, cocher alors la case : La victime a subi une violence verbale, puis la case : Injure, provocation, outrage.",
        level: 1,
      },
      { value: "Atteinte au respect dû aux morts", level: 2 },
    ],
  },
}

export const factGoodsGroups = {
  fgDeteriorations: {
    label: "Dégradation",
    options: [
      {
        id: "fgDeteriorations0",
        value: "Dégradation autre que par incendie",
        info: "Mobilier, véhicule, local, matériel, etc.",
        level: 1,
      },
      {
        id: "fgDeteriorations1",
        value: "Dégradation par incendie volontaire",
        info: "Local, matelas, mobilier, poubelle, véhicule, etc.",
        level: 3,
      },
      {
        id: "fgDeteriorations2",
        value: "Tags, graffitis, autres salissures",
        info: "Avec caractère ou non injurieux envers quelqu’un ou établissement/cabinet/officine. Si en plus le tag/graffiti a un caractère injurieux envers quelqu’un ou établissement/cabinet/officine, cocher la case correspondante dans la rubrique : La victime a subi une violence verbale.",
        level: 1,
      },
      {
        id: "fgDeteriorations3",
        value: "Squat et occupation",
        info: "D’un lieu, d’un bâtiment, sous/sol avec détérioration ou non (laisser des détritus, salissures): se laver dans une chambre vide, rester ou dormir dans une salle d’attente, squatter une pièce en sous-sol, etc.",
        level: 1,
      },
      { value: "Matériel de grande valeur (médical ou non)", level: 2 },
    ],
  },
  fgStealWithoutBreakins: {
    label: "Vol sans effraction",
    options: [
      {
        id: "fgStealWithoutBreakins0",
        value: "Objets professionnels ou personnels du personnel de santé",
        info: "Caducée, fonds de caisse, plaque professionnelle, ordonnancier, tampon professionnel, mobilier, masque, ramettes de papier, nourriture dans les frigos, etc.",
        level: 1,
      },
      {
        id: "fgStealWithoutBreakins1",
        value: "Médicaments",
        info: "Indiquer dans l'onglet 5/ Précisions le type de médicaments et au besoin le montant du préjudice.",
        level: 1,
      },
      {
        id: "fgStealWithoutBreakins2",
        value: "Matériel de grande valeur (médical ou non)",
        info: "outil informatique, endoscope, véhicule de l’établissement, etc.",
        level: 1,
      },
      {
        id: "fgStealWithoutBreakins3",
        value:
          "Effets personnels d’un patient, d’un accompagnant, d’une autre personne",
        level: 1,
      },
      {
        id: "fgStealWithoutBreakins4",
        value: "Informations",
        info: "Par le biais du piratage des dossiers patients, de l’ordinateur, rançonnage.",
        level: 2,
      },
    ],
  },
  fgStealWithBreakins: {
    label: "Vol avec effraction",
    options: [
      {
        id: "fgStealWithBreakins0",
        value: "Objets professionnels ou personnels du personnel de santé",
        info: "Caducée, fonds de caisse, plaque professionnelle, ordonnancier, tampon professionnel, mobilier, masque, ramettes de papier, nourriture dans les frigos, etc.",
        level: 2,
      },
      {
        id: "fgStealWithBreakins1",
        value: "Médicaments",
        info: "Indiquer dans l'onglet 5/ Précisions le type de médicaments et au besoin le montant du préjudice.",
        level: 2,
      },
      {
        id: "fgStealWithBreakins2",
        value: "Matériel de grande valeur (médical ou non)",
        info: "Outil informatique, endoscope, véhicule de l’établissement, etc.",
        level: 2,
      },
      {
        id: "fgStealWithBreakins3",
        value:
          "Effets personnels d’un patient, d’un accompagnant, d’une autre personne",
        level: 2,
      },
      {
        id: "fgStealWithBreakins4",
        value: "Informations",
        info: "Par le biais du piratage des dossiers patients, de l’ordinateur, rançonnage.",
        level: 2,
      },
    ],
  },
  fgStealAggravated: {
    label: "Vol aggravé",
    options: [
      {
        id: "fgStealAggravated0",
        value: "Vol à main armée",
        info: "Arme à feu, arme blanche, gaz lacrymogène, objet contondant : poing américain, tonfa, nunchaku, etc. Si vol de médicaments, indiquer dans l'onglet 5/ Précisions le type de médicaments et au besoin le montant du préjudice.",
        level: 3,
      },
      {
        id: "fgStealAggravated1",
        value: "Vol en réunion ou en bande organisée",
        info: "Vol sans arme mais commis par au moins deux personnes. Si vol de médicaments, indiquer dans l'onglet 5/ Précisions le type de médicaments et au besoin le montant du préjudice.",
        level: 3,
      },
    ],
  },
  fgOthers: {
    label: "Autres faits",
    options: [
      {
        id: "fgOthers0",
        value: "Port d’arme ou détention d’arme",
        info: "Arme à feu, arme blanche, gaz lacrymogène, objet contondant : poing américain, tonfa, nunchaku, etc. Cette atteinte ne nécessite pas forcément de remplir le masque « motifs ».",
        level: 2,
      },
      {
        id: "fgOthers1",
        value: "Escroquerie",
        info: "Ex. : à la suite d’un vol d’ordonnancier pour se faire remettre indument des médicaments, obtenir des droits indus (présenter une fausse attestation ou une attestation falsifiée, une fausse carte vitale, un faux arrêt de travail, etc.).",
        level: 2,
      },
      {
        id: "fgOthers2",
        value:
          "Abus frauduleux de l’état d’ignorance ou de la situation de faiblesse",
        info: "Maltraitance physique et/ou psychique sur une personne dont on connaît sa particulière vulnérabilité (minorité, âge, maladie, infirmité, déficience physique ou psychique, état de grossesse) en vue d’obtenir un acte ou une abstention qui lui sont gravement préjudiciables. Par exemple, lui soutirer de l'argent, lui faire signer un chèque en blanc, une procuration, etc.",
        level: 2,
      },
      {
        id: "fgOthers3",
        value: "Trafic de stupéfiants ou autre trafic",
        info: "Cigarettes, médicaments, etc.",
        level: 3,
      },
    ],
  },
}

export const thirdPartyOptions = [
  { value: "Personnel hospitalier" },
  { value: "Service de sécurité-sûreté" },
  {
    value:
      "Forces de l'ordre (police et gendarmerie nationales, police municipale)",
  },
  { value: "Sapeurs-pompiers" },
  {
    value: "Autre",
    precision: "thirdPartyPrecision",
  },
]

export const reasons = {
  rCausePatients: {
    label:
      "Refus ou contestation par le patient, le résident ou l’accompagnant/la famille",
    options: [
      {
        id: "rCausePatients0",
        value: "Du RDV donné (délai, horaire)",
      },
      {
        id: "rCausePatients1",
        value:
          "D’accepter le diagnostic, la décision thérapeutique/médicale/de sortie, etc.",
      },
      {
        id: "rCausePatients2",
        value: "D’accepter les soins",
      },
      {
        id: "rCausePatients3",
        value: "D’accepter les soins de toilette",
      },
      {
        id: "rCausePatients4",
        value: "De paiement",
      },
      {
        id: "rCausePatients5",
        value: "De participer à une activité extérieure",
      },
    ],
  },
  rCauseProfessionals: {
    label: "Refus par le professionnel de santé",
    options: [
      {
        id: "rCauseProfessionals0",
        value:
          "De prescription, de délivrance, de modification : d’une ordonnance, d’un arrêt de travail, d’hospitalisation",
      },
      {
        id: "rCauseProfessionals1",
        value:
          "De donner des informations médicales à une tierce personne non référent médical",
      },
      {
        id: "rCauseProfessionals2",
        value: "De soins",
      },
      {
        id: "rCauseProfessionals3",
        value: "De donner un RDV (délai, horaire)",
      },
      {
        id: "rCauseProfessionals3",
        value: "De vente pour non-conformité des droits",
        info: "Pièce justificative manquante, falsifiée, périmée (carte vitale, ordonnance, etc.)",
      },
      {
        id: "rCauseProfessionals4",
        value: "De vente pour d’autres raisons (hors stupéfiants)",
      },
    ],
  },
  rDiscords: {
    label: "Incompatibilité d’humeur et mésentente",
    options: [
      {
        id: "rDiscords0",
        value:
          "Entre le professionnel/collaborateur et le patient/résident/accompagnant/famille",
      },
      {
        id: "rDiscords1",
        value: "Entre les professionnels",
      },
      {
        id: "rDiscords2",
        value: "Entre les patients/résidents/accompagnants",
      },
      {
        id: "rDiscords3",
        value: "Autres (bandes, clans, squatteurs…)",
      },
    ],
  },
  rLifeRules: {
    label: "Non-respect des règles de vie",
    options: [
      {
        id: "rLifeRules0",
        value: "Retard du patient",
      },
      {
        id: "rLifeRules1",
        value:
          "Temps d’attente jugé excessif par le patient/résident/accompagnant/famille",
      },
      {
        id: "rLifeRules2",
        value: "Ordre de passage entre patients",
      },
      {
        id: "rLifeRules3",
        value: "Non-respect des conditions de séjour",
        info: "Règlement intérieur - droits et devoirs des patients, des accompagnants dans un établissement",
      },
      {
        id: "rLifeRules4",
        value:
          "Frustation/contrariété (pas de sortie, pas de cigarettes, pas de nourriture supplémentaire, etc.)",
      },
    ],
  },
  rFalsifications: {
    label:
      "Falsification ou non-conformité de documents médicaux et/ou administratifs",
    options: [
      {
        id: "rFalsifications0",
        value: "Document médical (ordonnance)",
      },
      {
        id: "rFalsifications1",
        value:
          "Document administratif (CNI, carte Vitale non mise à jour, etc.)",
      },
    ],
  },
  rDeficientCommunications: {
    label: "Communication défaillante",
    options: [
      {
        id: "rDeficientCommunications0",
        value: "Remarques de la part du professionnel/collaborateur",
      },
      {
        id: "rDeficientCommunications1",
        value:
          "Défaut d’information ou information incomplète du professionnel",
      },
      {
        id: "rDeficientCommunications2",
        value:
          "Reproche d’une communication non adaptée (termes trop techniques, difficultés de compréhension de la langue)",
      },
    ],
  },
  rOthers: {
    label: "Motifs divers",
    options: [
      {
        id: "rOthers0",
        value: "Atteinte au principe de laïcité",
        info: "À ne pas confondre avec la radicalisation (voir choix suivant). L'atteinte à la laïcité est le non-respect des devoirs de neutralité, de dignité, de réserve, d'exécuter ses fonctions. Ex. : ne pas vouloir serrer la main d'une personne du sexe opposé, ne pas vouloir soigner une personne du sexe opposé, refuser d'être dans la salle d'attente avec une personne d'un autre sexe, ne pas vouloir se faire soigner ou qu'un tiers refuse qu'un membre de sa famille soit soigné par un soignant du sexe opposé, installer un coin prière dans une partie de l'établissement, etc. Dans le privé on peut retrouver ces attitudes également.",
      },
      {
        id: "rOthers1",
        value: "Radicalisation",
        info: " À ne pas confondre avec l'atteinte au principe de laïcité. « La radicalisation est un processus par lequel un individu ou un groupe adopte des velléités de violence, directement liées à une idéologie extrémiste à contenu politique, social ou religieux qui conteste l'ordre établi sur le plan politique, social ou culturel. » Les trois critères cumulatifs de la radicalisation violente sont donc : => Un processus marqué par des ruptures comportementales ; => L'adhésion à une idéologie extrémiste ; => L'adoption de la violence (risque de passage à l'acte, soutien, apologie.",
      },
      {
        id: "rOthers2",
        value: "Caisse (vol de caisse, rendu de monnaie, etc.)",
      },
      {
        id: "rOthers3",
        value: "Réaction face à la douleur du soin",
      },
      {
        id: "rOthers4",
        value: "Patient sous stupéfiants",
      },
      {
        id: "rOthers5",
        value: "Autre",
        precision: "rOthersPrecision",
      },
    ],
  },
}

export const hours = [
  "Matin (7h-12h)",
  "Après-midi (12h-19h)",
  "Soirée (19h-00h)",
  "Nuit (00h-7h)",
]

export const insideLiberalLocations = [
  "Cabinet individuel",
  "Cabinet collectif",
  "Officine",
  "Laboratoire de biologie médicale",
]
export const outsideLiberalLocations = [
  "En face/à proximité du cabinet, de l’officine ou du laboratoire",
  "Au domicile du patient",
  "Sur le trajet entre le cabinet et le domicile du patient",
  "Sur le trajet entre votre domicile et votre lieu de travail",
]

export const liberalLocations = [
  ...insideLiberalLocations,
  ...outsideLiberalLocations,
]

export const etsMainLocations = [
  "Accueil Mère/enfant",
  "Addictologie",
  "Cancérologie",
  "Chirurgie",
  "CMP",
  "Dialyse",
  "Établissement pénitentiaire (autre que UHSI et UHSA)",
  "Gériatrie court séjour",
  "Gynécologie-obstétrique-maternité",
  "Hôpital de jour",
  "Imagerie médicale",
  "Laboratoire",
  "Médecine",
  "Morgue",
  "PASS",
  "Pédiatrie-néonatologie",
  "PUI",
  "Réanimation",
  "Rééducation",
  "SAU",
  "SMUR",
  "Soins palliatifs",
  "UHCD/UHTCD",
  "UMJ",
  "USIP",
  "USLD",
  "USMP",
  "Autre",
]

export const etsSecondaryLocations = [
  "Accueil, standard (de l’Ets ou d’un service de l’Ets)",
  "Atelier thérapeutique",
  "Bloc opératoire",
  "Bureau du personnel (médical ou non)",
  "Cafétéria/commerce",
  "Chambre du patient/résident",
  "Chambre sécurisée (détenu ou gardé à vue)",
  "Chambre d’isolement",
  "Domicile patient (intérieur)",
  "Domicile patient (extérieur : rue, parking, hall d’immeuble, ascenseur, escalier, palier)",
  "Dans l’enceinte : s/sol, jardin, parking,  zone de circulation dont ascenseur, escalier, couloir, toilettes",
  "À l’extérieur (voie publique, commerces)",
  "Espace d’apaisement",
  "Espace fumeurs",
  "Locaux des services de sécurité",
  "Magasin/entrepôt/local/services techniques",
  "Salle à manger",
  "Salle d’attente",
  "Salle de détente (patients/résidents : tv, jeux, etc.)",
  "Salle de pause du personnel",
  "Salle de réveil",
  "Trésorerie",
  "Unité de soins (box, bureau de consultation, salle de soins)",
  "Véhicule (dans le cadre d’un transport de patients/résidents)",
  "Vestiaire",
  "Autre",
]

export const jobs = [
  "Aide-soignant",
  "Ambulancier",
  "Assistant dentaire",
  "Assistant de régulation médicale",
  "Audioprothésiste",
  "Auxiliaire de puériculture",
  "Chirurgien-dentiste",
  "Diététicien",
  "Ergothérapeute",
  "Infirmier",
  "Manipulateur d'électroradiologie médicale",
  "Masseur-kinésithérapeute",
  "Médecin",
  "Médecin biologiste médical",
  "Opticien-lunetier",
  "Orthésiste",
  "Orthophoniste",
  "Orthoprothésiste",
  "Orthoptiste",
  "Pédicure-podologue",
  "Pharmacien",
  "Pharmacien biologiste médical",
  "Physicien médical",
  "Préparateur en pharmacie",
  "Prothésiste",
  "Psychomotricien",
  "Sage-femme",
  "Technicien de laboratoire médical",
]

export const healthJobs = [
  "Aide-soignant",
  "Ambulancier",
  "Assistant dentaire",
  "Assistant de régulation médicale",
  "Audioprothésiste",
  "Auxiliaire de puériculture",
  "Chirurgien-dentiste",
  "Diététicien",
  "Ergothérapeute",
  "Infirmier",
  "Manipulateur d'électroradiologie médicale",
  "Masseur-kinésithérapeute",
  "Médecin",
  "Médecin biologiste médical",
  "Opticien-lunetier",
  "Orthésiste",
  "Orthophoniste",
  "Orthoprothésiste",
  "Orthoptiste",
  "Pédicure-podologue",
  "Pharmacien",
  "Pharmacien biologiste médical",
  "Physicien médical",
  "Préparateur en pharmacie",
  "Prothésiste",
  "Psychomotricien",
  "Sage-femme",
  "Technicien de laboratoire médical",
]

export const jobsByOrders = {
  "Chirurgiens-Dentistes": ["Assistant dentaire", "Chirurgien-dentiste"],
  Infirmiers: ["Infirmier"],
  Médecins: ["Médecin", "Médecin biologiste médical"],
  "Sages-femmes": ["Sage-femme"],
  Pharmaciens: [
    "Pharmacien",
    "Préparateur en pharmacie",
    "Pharmacien biologiste médical",
  ],
  "Pédicures-podologues": ["Pédicure-podologue", "Podo-orthésiste"],
  "Masseurs-kinésithérapeutes": ["Masseur-kinésithérapeute"],
}

export const orders = Object.keys(jobsByOrders)

export const roles = [
  "Gestionnaire établissement",
  // "Gestionnaire multi-établissements",
  "Gestionnaire d'ordre",
  "Administrateur",
]

export const ages = ["- de 18 ans", "+ de 18 ans"]

export const genders = ["Masculin", "Féminin"]

const baseVictimsAuthors = [
  "Accompagnant/Visiteur/Famille",
  "Agent de sécurité-sûreté",
  "Détenu",
  "Étudiant en santé",
  "Patient/Résident",
  "Personnel administratif et technique",
  "Professionnel de santé",
  "Prestataire extérieur",
]

export const victimTypes = [...baseVictimsAuthors, "Établissement"].sort(
  (a, b) => a.localeCompare(b),
)

export const authorTypes = [...baseVictimsAuthors, "Inconnu", "Autre"].sort(
  (a, b) => a.localeCompare(b),
)

export const juridicStatus = ["Public", "Privé", "ESPIC"]

export const healthTypes = ["Étudiant en santé", "Professionnel de santé"]

export const noPersonTypes = ["Établissement", "Inconnu"]

export const otherType = ["Autre"]

export const pursuits = ["Non", "Main courante", "Plainte"]

export const pursuitComplaintsByValues = [
  "La (les) victime(s)",
  "L'établissement",
  "L'ordre",
]

export const ouiNonOptions = ["Oui", "Non"]

export const discernmentTroubles = [
  "Trouble psychique ou neuropsychique (TPN)",
  "Prise d’alcool",
  "Prise de produits stupéfiants",
  "Prise de médicaments",
  "Effet de l’anesthésie",
]

export const params = ["Date limite des déclarations"]

/** End of list */

export type SelectOption = {
  value: string
  label: string
}

export const buildSelectOptions = (array) =>
  array.map((item) => ({
    value: item,
    label: item,
  }))

const getSelectOption = (array: SelectOption[]) => (value: string) =>
  array.filter((item) => item.value === value)?.[0] || null

export const hoursOptions = buildSelectOptions(hours)
export const locationMainOptions = buildSelectOptions(etsMainLocations)
export const locationSecondaryOptions = buildSelectOptions(
  etsSecondaryLocations,
)

export const jobsOptions = buildSelectOptions(jobs)
export const healthJobOptions = buildSelectOptions(healthJobs)
export const victimTypesOptions = buildSelectOptions(victimTypes)
export const authorTypesOptions = buildSelectOptions(authorTypes)
export const ageOptions = buildSelectOptions(ages)
export const genderOptions = buildSelectOptions(genders)
export const rolesOptions = buildSelectOptions(roles)
export const juridicStatusOptions = buildSelectOptions(juridicStatus)
export const ordersOptions = buildSelectOptions(orders)

export const getRoleOption = getSelectOption(rolesOptions)
export const getJuridicStatusOption = getSelectOption(juridicStatusOptions)
export const getOrderOption = getSelectOption(ordersOptions)

export const isHealthType = (type: string): boolean =>
  healthTypes.includes(type)

export const isNoPersonType = (type: string): boolean =>
  noPersonTypes.includes(type)

export const isOtherType = (type: string): boolean => otherType.includes(type)

export const mainEts = {
  1: "1/5 Établissements sanitaires",
  2: "2/5 Premier recours",
  3: "3/5 Médico-social",
  4: "4/5 Social",
  5: "5/5 Établissements de formation",
}

export const secondEts = {
  // Établissements sanitaires
  1: "Médecine Chirurgie Obstétrique (MCO)",
  2: "MCO : Prise en charge du cancer",
  3: "Soins de suite et de réadaptation",
  4: "Psychatrie",
  5: "Hospitalisation à domicile / Soins à domicile",
  6: "Unités de soins de longue durée",
  // Premier recours
  7: "Imagerie médicale",
  8: "Laboratoire de biologie",
  9: "Dialyse",
  10: "Centre de santé",
  11: "Autres structures de ville",
  // Médico-social
  12: "Enfant handicapé",
  13: "Adulte handicapé",
  14: "Personne âgée",
  // Social
  15: "Foyers, résidences maisons relai et établissements sociaux d'hébergement et d'accueil",
  16: "Établissements de l'Aide sociale à l'enfance",
  17: "Etablissements ou services pour Adultes et d'aide aux  Familles en Difficulté",
  18: "Autres structures sociales",
  // Établissements de formation
  19: "Formation des personnels sanitaires",
  20: "Formation des personnels sociaux",
}

export const linkEts = {
  1: ["1", "2", "3", "4", "5", "6"],
  2: ["7", "8", "9", "10", "11"],
  3: ["12", "13", "14"],
  4: ["15", "16", "17", "18"],
  5: ["19", "20"],
}

export function findMainTypeActiviteEt(inputString) {
  // Find the key in secondEts that matches the input string
  let secondEtsKey = ""
  for (const key in secondEts) {
    if (secondEts[key] === inputString) {
      secondEtsKey = key
      break
    }
  }

  // If the input string is not found in secondEts, return null
  if (!secondEtsKey) return null

  // Find the main category that contains the found key in linkEts
  for (const mainKey in linkEts) {
    if (linkEts[mainKey].includes(secondEtsKey)) {
      return mainEts[mainKey].split(" ").slice(1).join(" ")
    }
  }

  // If no main category is found, return null
  return null
}

export function getAcronymeActiviteEt(inputString) {
  switch (inputString) {
    case "Établissements sanitaires":
      return "ES"
      break
    case "Premier recours":
      return "PR"
      break
    case "Médico-social":
      return "ESMS"
      break
    case "Social":
      return "Social"
      break
    case "Établissements de formation":
      return "EF-UFR"
      break
    default:
      return "autres"
  }
}

export const mainSctr = {
  1: "1/6 Secteurs",
  2: "2/6 Secteurs cliniques",
  3: "3/6 Secteurs aigus",
  4: "4/6 Plateaux médico-techniques",
  5: "5/6 Secteurs spécifiques",
  6: "6/6 Autres secteurs",
}

export const secondSctr = {
  // Secteurs
  1: "Secteur Hospitalisation",
  2: "Secteur Hôpital de jour",
  3: "Secteur Ambulatoires/Consultations externes",
  4: "Secteur d'activité : Salle de formation, d'accompagnement, de prise en charge, travail",
  5: "Domicile du patient/usager",
  // Secteurs cliniques
  6: "Accueil Mère/Enfant",
  7: "Addictologie",
  8: "Cancérologie/Oncologie",
  9: "Centre médico-pyschologique (CMP)",
  10: "Chirurgie",
  11: "Dialyse",
  12: "Gériatrie",
  13: "Gynécologie-obstétrique-maternité",
  14: "Médecine",
  15: "Néonatologie",
  16: "Pédiatrie",
  17: "Permanences d'accès aux soins de santé (PASS)",
  18: "Autres secteurs de spécialité",
  // Secteurs aigüs
  19: "Bloc opératoire",
  20: "Chambre de soins intensifs",
  21: "Espace d'apaisement",
  22: "Réanimation/Soins intensifs/Surveillance continue",
  23: "Service d'accueil des urgences (SAU)",
  24: "SMUR/Ambulances",
  25: "Soins palliatifs",
  26: "Unité d'hospitalisation de courte et très courte durée (UHCD/UHTCD)",
  27: "Unité pour malades difficiles (UMD)",
  28: "Unité de soins intensifs psychiatriques (USIP)",
  // Plateaux techniques
  29: "Atelier thérapeutique",
  30: "Imagerie médicale",
  31: "Laboratoire",
  32: "Médecine nucléaire",
  33: "Pharmacies à usage intérieur (PUI)",
  34: "Radiothérapie",
  35: "Rééducation",
  // Secteurs spécifiques
  36: "Chambre mortuaire",
  37: "Secteurs pour détenus (chambres sécurisées, UHSI, UHSA, USMP)",
  38: "Unité médico-judiciaire (UMJ)",
  // Autres lieux ou secteurs
  50: "Autre",
}

export const linkSctr = {
  1: ["1", "2", "3", "4", "5"],
  2: ["6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"],
  3: ["19", "20", "21", "22", "23", "24", "25", "26", "27", "28"],
  4: ["29", "30", "31", "32", "33", "34", "35"],
  5: ["36", "37", "38"],
  6: ["50"],
}

export const otherSctr = {
  1: "Établissement (accueil du public) : bureau des entrées - d'admission, salle d'attente, secrétariat, standard, trésorerie",
  2: "Établissement (chambre du patient/résident)",
  3: "Établissement (espaces communs) : ascenseur, couloir, escalier, hall, sanitaires",
  4: "Établissement (formation) : salle de cours, amphithéâtre",
  5: "Établissement (local de vie) : bibliothèque, cafétéria, commerce, espace fumeurs, réfectoire, salle à manger - de jeu - de détente - TV, salon",
  6: "Établissement (local réservé au personnel) : bureau, salle de pause, service administratif, vestiaire",
  7: "Établissement (site) : espaces extérieurs, jardin, parking, voie de circulation",
  8: "Établissement (unité de soins) : box, bureau de consultation, salle d'examens, salle de soins",
  9: "Établissement (zone technique ou logistique) : atelier, cuisine, entrepôt, local technique, magasin, sécurité-sûreté",
  10: "Hors établissement (domicile du patient) : intérieur",
  11: "Hors établissement (voie publique) : abords/hall d'immeuble, rue, parking, véhicule",
}

export const linkEtsSctr = {
  1: [
    "1",
    "2",
    "3",
    "6",
    "7",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "22",
    "23",
    "24",
    "25",
    "26",
    "30",
    "31",
    "33",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  2: [
    "1",
    "2",
    "3",
    "8",
    "18",
    "25",
    "30",
    "32",
    "34",
    "35",
    "36",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  3: [
    "1",
    "2",
    "3",
    "6",
    "7",
    "8",
    "12",
    "16",
    "18",
    "25",
    "31",
    "33",
    "35",
    "36",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  4: [
    "1",
    "2",
    "3",
    "6",
    "7",
    "9",
    "17",
    "18",
    "20",
    "21",
    "23",
    "27",
    "28",
    "29",
    "33",
    "37",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  5: [
    "5",
    "11",
    "33",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  6: [
    "1",
    "2",
    "3",
    "33",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  7: [
    "3",
    "30",
    "32",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  8: [
    "3",
    "5",
    "31",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  9: [
    "2",
    "3",
    "5",
    "11",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  10: ["3", "5", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49"],
  11: [
    "1",
    "2",
    "3",
    "5",
    "24",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  12: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "29",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  13: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "29",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  14: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "29",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ],
  15: ["4", "5", "40", "41", "42", "43", "44", "45", "47", "48", "49"],
  16: ["4", "5", "40", "41", "42", "43", "44", "45", "47", "48", "49"],
  17: ["4", "5", "40", "41", "42", "43", "44", "45", "47", "48", "49"],
  18: ["3", "40", "41", "42", "43", "44", "45", "47", "48", "49"],
  19: ["3", "40", "42", "43", "44", "45", "47", "48", "49"],
  20: ["3", "40", "42", "43", "44", "45", "47", "48", "49"],
}

